<template>
  <section>
    <v-dialog persistent v-model="dialog" max-width="650">
      <v-card class="rounded-lg">
        
        <v-card-title class="d-flex flex-row justify-space-between">
          <div>
            <div class="secondary--text primary-font fw600 f14">{{ data.hasOwnProperty('topic_id') ? 'SUBTOPIC' : 'TOPIC' }}</div>
          </div>
          <v-btn icon @click="close()">
            <v-icon size="22">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        
        <h3 class="primary--text primary-font fw600 px-6">{{ data.title }}</h3>
        <section v-if="data.learning_material && !update" flat class="px-6 mb-3">
          <vimeo v-if="['mp4', 'youtube'].includes(data.learning_material.file_type) && showVimeo"
            :uri="data.learning_material.url"
            :type="data.learning_material.file_type"
            :data="data"
            class="fade"
          />
          <div id="container-frame" class="container-frame" v-else>
              <iframe 
                  v-if="['ppt', 'pptx', 'docx'].includes(data.learning_material.file_type)"
                  id="material-frame"
                  :src="`https://view.officeapps.live.com/op/embed.aspx?src=${data.learning_material.url}`"
                  class="responsive-iframe"
                  allowfullscreen
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
              ></iframe>
              <v-img 
                  v-else-if="['png', 'jpg', 'jpeg'].includes(data.learning_material.file_type)"
                  id="material-iframe"
                  :src="data.learning_material.url"
              />
              <iframe 
                  v-else-if="['scorm_file'].includes(data.learning_material.file_type)"
                  id="material-frame"
                  :src="data.scorm ? data.scorm.preview_link : data.learning_material.url"
                  class="responsive-iframe"
                  allowfullscreen
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
              ></iframe>
              <iframe 
                  v-else
                  id="material-iframe"
                  :src="data.learning_material.url"
                  class="responsive-iframe"
                  allowfullscreen
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
              ></iframe>  
          </div>
        </section>
        <v-card-text v-if="!update" class="d-flex flex-row">
          <v-spacer />
          <v-btn
            v-if="!['scorm_file'].includes(data.learning_material.file_type)"
            text
            class="text-capitalize primary-font f14 fw500 no-space"
            color="primary"
            @click="update = true"
          >
            <v-icon left small>mdi-pencil-outline</v-icon>
            Update Material
          </v-btn>
        </v-card-text>
        <MaterialForm v-if="update" :item="material" :type="type" @close="close()"/>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapMutations } from "vuex";
import MaterialForm from "./MaterialForm.vue";
export default {
  props: ["dialog", "data", "type"],
  components: {
    MaterialForm,
  },
  data: () => ({
    update: false,
    material: null,
    showVimeo: false
  }),
  watch: {
    dialog(val){
      const access_payload = localStorage.getItem('access_payload');
      if(val){
        this.material = JSON.parse(JSON.stringify(this.data))
        setTimeout(() => {
            this.showVimeo = true
            localStorage.setItem('access_payload', access_payload);
        }, 1000)
      } else {
        this.showVimeo = false
        localStorage.setItem('access_payload', access_payload);
      }
    }
  },
  mounted(){
    console.log('mouneted view mat')
  },
  created(){
    console.log('created view mat')
  },
  methods: {
    ...mapMutations(["playerMutation"]),

    close() {
      this.playerMutation({
        action: "stop",
        duration: 0,
      });
      
      this.update = false;
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
    .container-frame {
        position: relative;
        overflow: hidden;
        width: 100%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    }

    .responsive-iframe {
        height: 54vh;
    }

    @media only screen and (max-width: 768px){
    .responsive-iframe {
        height: 54vh;
        width: 100%;
    }
}

</style>
